import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import IMAGES from "../img/image";
import axios from "axios";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import getUserData from "../utils/userDataService.js";
import { setUser } from "../redux/features/userSlice.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import WalletIcon from "@mui/icons-material/Wallet";
import InfoIcon from "@mui/icons-material/Info";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SafetyCheckIcon from "@mui/icons-material/SafetyCheck";
import LockResetIcon from "@mui/icons-material/LockReset";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HelpIcon from "@mui/icons-material/Help";
import VerifiedIcon from "@mui/icons-material/Verified";
import "./ProductInfo.css";
import Spinner from "../components/loader/Spinner.js";
import { Badge, Chip } from "@mui/material";

const ProductInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [playerCheck, setPlayerCheck] = useState(null);
  const [userAllow, setUserAllow] = useState(false);
  const [product, setProduct] = useState(0);
  const [showImage, setShowImage] = useState(0);
  const [error, setError] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState("");
  //!NEW STATE
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  const [profit, setProfit] = useState("");
  //! API BASED
  const [orderId, setOrderId] = useState(false);
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [balance, setBalance] = useState("");
  const [orderSuccess, setOrderSuccess] = useState(true);
  const [data, setData] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [couponApplied, setCouponApplied] = useState(null);
  const [discount, setDiscount] = useState("");
  const [finalAmount, setFinalAmount] = useState("");
  const [couponName, setCouponName] = useState("");
  const [showBuyPopup, setShowBuyPopup] = useState(false);

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
    getAllCoupons();
  }, []);

  const getAllCoupons = async () => {
    try {
      const res = await axios.get("/api/admin/get-coupons");
      if (res.data.success) {
        setData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function applyCoupon(e) {
    e.preventDefault();
    if (data?.length === 0 || data === null) {
      return message.error("No Coupon Found");
    }
    if (coupon === "") {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1500);
      return;
    }
    // find coupon
    const couponCode = data && data?.find((item) => item.name === coupon);
    if (!couponCode) {
      return message.error("No coupon found");
    }
    setCouponName(couponCode?.name);

    if (couponCode?.minValue >= selectedPrice) {
      message.error(`Minimum order value is: ${couponCode?.minValue}`);
      return;
    }
    //
    if (coupon) {
      setCouponApplied(true);
      setDiscount(couponCode?.discount);
      setFinalAmount(selectedPrice - couponCode?.discount);
      message.success("Coupon applied success");
    } else {
      message.error("No coupon found");
    }
  }

  const removeDiscount = () => {
    setCouponApplied(false);
    setFinalAmount((prev) => prev + discount);
  };

  function setPriceAndId(amount) {
    if (user?.reseller === "yes") {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.resPrice;
      setSelectedPrice(price);
      setFinalAmount(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
      const pf = product?.cost?.find((item) => item.amount === amount)?.profit;
      setProfit(pf);
      if (couponApplied) {
        setCouponApplied(false);
      }
    } else {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.price;
      setSelectedPrice(price);
      setFinalAmount(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
      const pf = product?.cost?.find((item) => item.amount === amount)?.profit;
      setProfit(pf);
      if (couponApplied) {
        setCouponApplied(false);
      }
    }
  }

  const getProduct = async () => {
    try {
      const res = await axios.post("/api/product/get-product-by-name", {
        name: params.name,
      });
      if (res.data.success) {
        // console.log(res.data.data)
        setProduct(res.data.data);
        const defaultAmount = res.data.data?.cost?.[0]?.amount;
        const defaultPrice =
          user && user?.reseller === "yes"
            ? res.data.data?.cost?.[0]?.resPrice
            : res.data.data?.cost?.[0]?.price;
        const defaultId = res.data.data?.cost?.[0]?.id;
        const defaultOriginalPrice = res.data.data?.cost?.[0]?.profit;
        setAmount(defaultAmount);
        setSelectedPrice(defaultPrice);
        setFinalAmount(defaultPrice);
        setProductId(defaultId);
        setProfit(defaultOriginalPrice);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProduct();
  }, [user]);

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
    setOrderId(orderId);
  };

  useEffect(() => {
    generateOrderId();
  }, []);

  async function handleCheckPlayer() {
    if (userId === "" || zoneId === "") {
      return message.error(
        `${userId === "" ? "Enter User ID" : "Enter (   Zone ID   )"}`
      );
    }
    try {
      const object = {
        region: "brazil",
        userid: userId,
        zoneid: zoneId,
        productid: "13",
      };
      const res = await axios.post("/api/payment/get-role", object);
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        setLoading(false);
        if (product?.use === res.data.use) {
          setUserAllow(true);
        } else {
          setUserAllow(false);
        }
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function checkPlaceOrder(e, mode) {
    try {

      // check if user logged in
      if (!user) {
        message.error('Please login to your account first!')
        navigate('/login')
        return
      }

      //checking if product is out of stock
      if (product?.stock.toLowerCase() === 'no') {
        message.error('Out of stock! Please try again later')

        return
      }

      // if (
      //   Array.isArray(product?.category) &&
      //   product?.category?.includes("Preorder")
      // ) {
      //   //moogold routes
      //   if (mode === "UPI") {
      //     handlePreorderUpi(e);
      //   } else {
      //     handlePreorderWallet(e);
      //   }
      // }

      if (product?.api === "no") {
        if (userId === "") {
          return message.error("Some Fields are missing");
        }
      } else if (product.api === "yes" && product?.apiName === "yokcash") {
        if (
          product?.gameName === "Clash Of Clans" ||
          product?.gameName === "Free Fire"
        ) {
          if (userId === "") {
            return message.error("Enter User ID");
          }
        }
        if (
          product?.gameName === "Genshin Impact" ||
          product?.gameName === "ML Region Luar" ||
          product?.gameName === "Mobile Legends"
        ) {
          if (userId === "") {
            return message.error("Enter User ID");
          }
          if (zoneId === "") {
            return message.error("Select Server");
          }
        }
      } else {
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Enter Zone ID");
        }
        if (playerCheck === null) {
          return message.error("Check Your Player Name");
        }
      }

      if (product?.api === "yes") {
        if (product?.apiName === "yokcash") {
          if (mode === "UPI") {
            handleYokcashUpiOrder(e);
          } else {
            handleYokcashWalletOrder(e);
          }
        } else if (product?.apiName === "smileOne") {
          if (mode === "UPI") {
            handleSmileOneUpiOrder(e);
          } else {
            handleSmileOneWalletOrder(e);
          }
        } else if (product?.apiName === "moogold") {
          if (mode === "UPI") {
            handleMoogoldUpiOrder(e);
          } else {
            handleMoogoldWalletOrder(e);
          }
        }
      } else {
        if (mode === "UPI") {
          handleUpiOrder(e);
        } else {
          handleWalletOrder(e);
        }
      }
    } catch (error) {
      console.log(error)
    }
    finally {
      setLoading(false)
    }
  }

  // yokcash
  async function handleYokcashUpiOrder(e) {
    e.preventDefault();
    // console.log(product['_id'])
    // return;
    setLoading(true);
    try {
      const paymentObject = {
        scannerIncluded: true,
        orderId: orderId,
        amount: finalAmount,
        customerName: user?.fname,
        customerEmail: user?.email,
        customerNumber: user?.mobile,
        redirectUrl: product?.category?.includes('Preorder') ? "https://deeragames.in/api/timedOrder/yokcash/status" : "https://deeragames.in/api/yokcash/status",
        paymentNote:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          profit +
          "@" +
          amount +
          "@" +
          selectedPrice +
          '@' +
          product['_id'] +
          '@' +
          user['_id'],

      };

      // take care of preorder items
      if (Array.isArray(product?.category) && product?.category?.includes('Preorder')) {
        //  TODO handle yokcash preorder items
        const response = await axios.post("/api/timedOrder/yokcash/create", paymentObject, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        if (response.data.success && response.data.data.paymentUrl) {
          window.location.href = response.data.data.paymentUrl;
        } else {
          message.error(response.data.message);
          setLoading(false);
        }

        return;
      }


      const response = await axios.post("/api/yokcash/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl;
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }
  async function handleYokcashWalletOrder(e) {
    e.preventDefault();
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    setLoading(true);
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: finalAmount,
        product_name: amount,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        product_id: product['_id'],
        user_id: user['_id'],
        paymentNote:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          profit +
          "@" +
          selectedPrice,
      };

      // take care of preorder items
      if (Array.isArray(product?.category) && product?.category?.includes('Preorder')) {
        //  TODO handle yokcash preorder items
        const response = await axios.post("/api/timedOrder/yokcash/wallet", paymentObject, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        console.log(response)
        if (response.data.success) {
          message.success(response.data.message);
          navigate("/success");
        } else {
          setLoading(false);
          message.error(response.data.message);
          navigate("/failure");
        }

        return;
      }


      const res = await axios.post("/api/yokcash/wallet", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      // console.log(res);
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
      } else {
        setLoading(false);
        message.error(res.data.message);
        navigate("/failure");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  // smile
  const handleSmileOneUpiOrder = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const paymentObject = {
        scannerIncluded: true,
        orderId: orderId,
        amount: finalAmount,
        customerName: user?.fname,
        customerEmail: user?.email,
        customerNumber: user?.mobile,
        redirectUrl: Array.isArray(product?.category) && product?.category?.includes('Preorder') ? 'https://deeragames.in/api/timedOrder/smileone/status' : "https://deeragames.in/api/smile/status",
        paymentNote:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          amount +
          "@" +
          product?.region +
          "@" +
          product?.name +
          "@" +
          profit +
          "@" +
          selectedPrice +
          '@' +
          product['_id'] +
          '@' +
          user['_id'],
      };


      // take care of preorder items
      if (Array.isArray(product?.category) && product?.category?.includes('Preorder')) {
        //  TODO handle yokcash preorder items
        const response = await axios.post("/api/timedOrder/smileone/create", paymentObject, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        // redirecting user
        if (response.data.success && response.data.data.paymentUrl) {
          window.location.href = response.data.data.paymentUrl;
        } else {
          message.error(response.data.message);
          setLoading(false);
        }

        return;
      }

      const response = await axios.post("/api/smile/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      // redirecting user
      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl;
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleSmileOneWalletOrder = async (e) => {
    e.preventDefault();
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    try {
      const orderObject = {
        client_txn_id: orderId,
        userid: userId.trim(),
        zoneid: zoneId.trim(),
        productid: productId,
        region: product.region,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        pname: product?.name,
        amount: amount,
        price: finalAmount,
        ogPrice: selectedPrice,
        profit: profit,
        productObjid: product['_id'],
        userObjid: user['_id'],
      };
      setLoading(true);

      // take care of preorder items
      if (Array.isArray(product?.category) && product?.category?.includes('Preorder')) {
        //  TODO handle smileone preorder items
        const response = await axios.post("/api/timedOrder/smileone/wallet", orderObject, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        console.log(response)
        if (response.data.success) {
          message.success(response.data.message);
          navigate("/success");
        } else {
          setLoading(false);
          message.error(response.data.message);
          navigate("/failure");
        }

        return;
      }

      const res = await axios.post("/api/smile/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
      } else {
        setLoading(false);
        message.error(res.data.message);
        navigate("/failure");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // moogold
  async function handleMoogoldUpiOrder(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const paymentObject = {
        scannerIncluded: true,
        orderId: orderId,
        amount: finalAmount,
        customerName: user?.fname,
        customerEmail: user?.email,
        customerNumber: user?.mobile,
        redirectUrl: "https://deeragames.in/api/moogold/status",
        paymentNote:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount +
          "@" +
          profit +
          "@" +
          product?.gameName +
          "@" +
          selectedPrice,
      };
      const response = await axios.post("/api/moogold/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      // redirecting user
      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl;
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }
  async function handleMoogoldWalletOrder(e) {
    e.preventDefault();
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    try {
      const orderObject = {
        api: "no",
        order_id: orderId,
        txn_amount: finalAmount,
        product_name: product?.gameName,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount +
          "@" +
          profit +
          "@" +
          selectedPrice,
      };

      setLoading(true);
      const res = await axios.post("/api/moogold/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
      } else {
        setLoading(false);
        message.error(res.data.message);
        navigate("/failure");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  // manual
  const handleUpiOrder = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const paymentObject = {
        scannerIncluded: true,
        orderId: orderId,
        amount: finalAmount,
        customerName: user?.fname,
        customerNumber: user?.mobile,
        customerEmail: user?.email,
        redirectUrl: "https://deeragames.in/api/manual/status",
        paymentNote:
          userId.trim() + "@" + amount + "@" + product?.name + "@" + profit,
      };

      const response = await axios.post("/api/manual/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl;
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleWalletOrder = async (e) => {
    e.preventDefault();
    try {
      const orderObject = {
        api: "no",
        client_txn_id: orderId,
        userid: userId.trim(),
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        pname: product?.name,
        amount: amount,
        price: finalAmount,
        profit: profit,
      };

      setLoading(true);
      const res = await axios.post("/api/manual/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
      } else {
        message.error(res.data.message);
        setLoading(false);
        navigate("/failure");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // pre order
  async function handlePreorderUpi(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        scannerIncluded: true,
        orderId: orderId,
        amount: finalAmount,
        customerName: user?.fname,
        customerEmail: user?.email,
        customerNumber: user?.mobile,
        redirectUrl: "https://deeragames.in/api/preorder/status",
        paymentNote:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          profit +
          "@" +
          amount +
          "@" +
          selectedPrice,
      };

      const response = await axios.post("/api/preorder/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handlePreorderWallet = async (e) => {
    try {
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className="product-info-container">
        {loading && (
          <div className="loading-container">
            <Spinner />
          </div>
        )}
        {/* GAME  */}
        <div className="game-name">
          <div className="image">
            <img src={`/${product?.image}`} alt="" />
          </div>
          <div className="content">

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <h4>{product?.name}</h4>
              {product?.stock?.toLowerCase() === 'no' ? <span style={{
                backgroundColor: '#c62828',
                width: "max-content",
                borderRadius: '30px',
                padding: '3px 8px',
                fontSize: '0.9girem',
                position: 'relative',
                top: '-12px',
                bottom: '3px',
                fontWeight: 'bold',
              }}>Out of Stock!</span> : ''}
            </div>

            {/* <h4>{product?.name}</h4> */}
            <p>{product?.desc}</p>
            <div className="features">
              <button>
                <SupportAgentIcon className="icon me-1" />
                24/7 Customer Support
              </button>
              <button>
                <SafetyCheckIcon className="icon me-1" />
                Safe Payment
              </button>
              <button>
                <LockResetIcon className="icon me-1" />
                Service Guarantee
              </button>
              <button>
                <ElectricBoltIcon className="icon me-1" />
                Instant Delivery
              </button>
            </div>
          </div>
        </div>
        {/* DESC & YOUTUBE LINK */}
        <div className="yt-container bg-fields">
          <h4>Game Description</h4>
          <p>{product?.descTwo}</p>
          <div className="yt">
            <Link to={product?.youtubeLink} target="_blank">
              <button className="ytbtn">
                <InfoIcon className="icon text-white" />
                How to Purchase
              </button>
            </Link>
          </div>
        </div>

        <div className="product-info-content mb-2">
          <div className="left">
            {/* ====================== PACKAGE ===============  */}
            <div className="bg-fields d-none d-lg-block">
              <h5>Select Package</h5>
              <hr />
              <div className="p-amount">
                {product?.cost?.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        setAmount(item.amount);
                        setPriceAndId(item.amount);
                      }}
                      key={index}
                      className={`amount ${amount === item?.amount && "active"
                        }`}
                    >
                      <div className="pimg">
                        <img src={item?.pimg} alt="" />{" "}
                      </div>
                      <div className="price">
                        <p className="m-0">{item?.amount}</p>
                        <span className="m-0">
                          ₹
                          {user?.reseller === "yes"
                            ? item?.resPrice
                            : item?.price}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="right">
            {/* ====================== USERID ===============  */}
            <div className="bg-fields">
              <h5>Enter IDs</h5>
              <hr />
              {product?.apiName === "smileOne" ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="User ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <input
                    className="player-tag"
                    type="text"
                    name="zoneid"
                    placeholder="(    ZONE ID    )"
                    onChange={(e) => setZoneId(e.target.value)}
                    value={zoneId}
                  />
                  <button className="buy-now" onClick={handleCheckPlayer}>
                    Check
                    {loading && (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                  {playerCheck !== null && userAllow && (
                    <p className="playername text-white bg-success">
                      {playerCheck && "Username: " + playerCheck}
                    </p>
                  )}
                  {playerCheck !== null && !userAllow && (
                    <p className="playername text-white bg-danger">
                      {playerCheck && "Username: " + playerCheck}
                    </p>
                  )}
                </>
              ) : product?.apiName === "yokcash" &&
                (product?.gameName === "ML Region Luar" ||
                  product?.gameName === "Mobile Legends") ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="USER ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <input
                    className="player-tag"
                    type="text"
                    name="zoneid"
                    placeholder="(    ZONE ID    )"
                    onChange={(e) => setZoneId(e.target.value)}
                    value={zoneId}
                  />
                  <button className="buy-now mb-2" onClick={handleCheckPlayer}>
                    Check Username
                    {loading && (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                  {playerCheck !== null && (
                    <p className="playername text-white bg-success">
                      {playerCheck && "Username: " + playerCheck}
                    </p>
                  )}
                </>
              ) : product?.apiName === "yokcash" &&
                (product?.gameName === "Genshin Impact" ||
                  product?.gameName === "Honkai Star Rail") ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="USER ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <select
                    name="zoneid"
                    className="form-select"
                    onChange={(e) => setZoneId(e.target.value)}
                  >
                    <option value="">Select Server</option>
                    <option value="America">America</option>
                    <option value="Asia">Asia</option>
                    <option value="Europe">Europe</option>
                    <option value="TW_HK_MO">TW_HK_MO</option>
                  </select>
                  <span className="text-danger">
                    {error &&
                      "First Check your username by clicking check button"}
                  </span>
                </>
              ) : product?.apiName === "yokcash" &&
                (product?.gameName === "Free Fire" ||
                  product?.gameName === "Call Of Duty Mobile" ||
                  product?.gameName === "PUBG Mobile" ||
                  product?.gameName === "Valorant" ||
                  product?.gameName === "Clash Of Clans" ||
                  product?.gameName === "League of Legends Wild Rift") ? (
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder={`${product?.tag}`}
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              ) : product?.apiName === "moogold" &&
                (product?.gameName === "15145" ||
                  product?.gameName === "2362359" ||
                  product?.gameName === "5846232" ||
                  product?.gameName === "6637539" ||
                  product?.gameName === "8957883" ||
                  product?.gameName === "8996566" ||
                  product?.gameName === "4690648") ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="User ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <input
                    className="player-tag"
                    type="text"
                    name="zoneid"
                    placeholder="(    ZONE ID    )"
                    onChange={(e) => setZoneId(e.target.value)}
                    value={zoneId}
                  />
                  <button className="buy-now" onClick={handleCheckPlayer}>
                    Check
                    {loading && (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                  {playerCheck !== null && (
                    <p className="playername text-white bg-success">
                      {playerCheck && "Username: " + playerCheck}
                    </p>
                  )}
                </>
              ) : product?.apiName === "moogold" &&
                product?.gameName === "428075" ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="USER ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <select
                    name="zoneid"
                    className="form-select"
                    onChange={(e) => setZoneId(e.target.value)}
                  >
                    <option value="">Select Server</option>
                    <option value="America">America</option>
                    <option value="Asia">Asia</option>
                    <option value="Europe">Europe</option>
                    <option value="TW, HK, MO">TW, HK, MO</option>
                  </select>
                  <span className="text-danger">
                    {error &&
                      "First Check your username by clicking check button"}
                  </span>
                </>
              ) : (
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder={`${product?.tag}`}
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              )}
            </div>
            {/* ====================== PACKAGE ===============  */}
            <div className="bg-fields d-block d-lg-none">
              <h5>Select Package</h5>
              <hr />
              <div className="p-amount">
                {product?.cost?.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        setAmount(item.amount);
                        setPriceAndId(item.amount);
                      }}
                      key={index}
                      className={`amount ${amount === item?.amount && "active"
                        }`}
                    >
                      <div className="pimg">
                        <img src={item?.pimg} alt="" />{" "}
                      </div>
                      <div className="price">
                        <p className="m-0">{item?.amount}</p>
                        <span className="m-0">
                          ₹
                          {user?.reseller === "yes"
                            ? item?.resPrice
                            : item?.price}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* ====================== COUPON ===============  */}
            <div className="bg-fields">
              {couponApplied ? (
                <>
                  <h5>Discount Applied</h5>
                  <div className="coupon-tag">
                    <p className="m-0">
                      {couponName} <CheckCircleOutlineIcon className="icon" />
                    </p>
                    <button className="remove-coupon" onClick={removeDiscount}>
                      Remove
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <h5>Apply Coupon</h5>
                  <div className="coupon-box">
                    <input
                      className="player-tag m-0"
                      type="text"
                      name="coupon"
                      placeholder="Enter Coupon"
                      onChange={(e) => setCoupon(e.target.value)}
                      value={coupon}
                    />
                    <button onClick={applyCoupon}>Apply</button>
                  </div>
                </>
              )}
              {error && coupon === "" && (
                <span className="text-danger">Enter valid coupon</span>
              )}
            </div>
            {/* ====================== PAYMENT METHOD ===============  */}
            <div className="bg-fields">
              {product?.apiName !== "smileOne" ? (
                <button
                  onClick={() => setShowBuyPopup(true)}
                  className="buy-now"
                >
                  Buy Now
                </button>
              ) : userAllow ? (
                <button
                  onClick={() => setShowBuyPopup(true)}
                  className="buy-now"
                >
                  Buy Now
                </button>
              ) : (
                playerCheck && (
                  <button disabled={true} className="buy-now">
                    This ID is not Allowed
                  </button>
                )
              )}
            </div>
            <div
              className={`${showBuyPopup && "bd"}`}
              onClick={() => setShowBuyPopup(!showBuyPopup)}
            ></div>
            <div className={`buy-popup ${showBuyPopup && "active"}`}>
              <HelpIcon className="icon" />
              <h2>Confirm your order!</h2>
              <div className="orderdetails">
                <div className="confirmproduct">
                  <div className="img">
                    <img
                      src={`https://deeragames.in/${product?.image}`}
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <p>{product?.name}</p>
                    <span>
                      {playerCheck}
                      <VerifiedIcon className="icon" />
                    </span>
                  </div>
                </div>
                <div className="item">
                  <span>Item</span>
                  <span>{amount}</span>
                </div>
                <div className="item">
                  <span>User ID</span>
                  <span>{userId}</span>
                </div>
                <div className="item">
                  <span>Price</span>
                  <span>{finalAmount}</span>
                </div>
              </div>
              <div className="buybtns">
                <button
                  disabled={loading}
                  onClick={(e) => checkPlaceOrder(e, "WALLET")}
                >
                  Pay via Wallet
                </button>

                {

                  // product?.category?.includes('Preorder') ? <button
                  //   disabled={true}
                  //   style={{ cursor: 'not-allowed' }}
                  // >
                  //   UPI Coming Soon
                  // </button> :
                  <button
                    disabled={loading}
                    onClick={(e) => checkPlaceOrder(e, "UPI")}
                  >
                    Pay via UPI
                  </button>
                }


              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductInfo;
