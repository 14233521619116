import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import WalletIcon from "@mui/icons-material/Wallet";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LogoutIcon from "@mui/icons-material/Logout";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

const APIDocsContentLayout = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.clear("token");
        navigate("/login");
    };

    return (
        <div className="dashboard-container">
            <div className="dashboard-menu">
                <h4>Getting Started</h4>
                <ul>
                    <li
                        className={`${location.pathname === "/api-docs" && "active"}`}
                    >
                        <Link to="/api-docs">
                            <DashboardIcon className="me-2" />
                            Pre-Condition
                        </Link>


                    </li>
                    <div className={`pre-order-items ${location.pathname === "/api-docs" ? 'd-block' : 'd-none'} `}>
                        <li>lorem</li>
                        <li>Ipsum</li>
                    </div>
                    <li className={`${location.pathname === "/wallet" && "active"}`}>
                        <Link to="/">
                            <WalletIcon className="me-2" />
                            Post
                        </Link>

                    </li>
                    <div className={`pre-order-items ${location.pathname === "/wallet" ? 'd-block' : 'd-none'} `}>
                        <li>lorem</li>
                        <li>Ipsum</li>
                    </div>

                </ul>
            </div>
            <div className="dashboard-content">{children}</div>
        </div>
    );
};

export default APIDocsContentLayout;
