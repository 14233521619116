import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ForgotPass from "./pages/ForgotPass";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Success from "./pages/Success";
import Failure from "./pages/Failure";
import Dashboard from "./user/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import ResetPassword from "./pages/ResetPassword";
import AdminDashboard from "./admin/AdminDashboard";
import AdminUsers from "./admin/AdminUsers";
import AdminResellers from "./admin/AdminResellers.js";
import EditUser from "./admin/EditUser";
import AdminPayments from "./admin/AdminPayments";
import AdminFlashSale from "./admin/AdminFlashSale.js";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import AdminBulkEmail from "./admin/AdminBulkEmail";
import AdminLogin from "./admin/AdminLogin";
import PhoneSkins from "./pages/PhoneSkins";
import Search from "./pages/Search";
import AdminProduct from "./admin/AdminProduct";
import AdminAddProduct from "./admin/AdminAddProduct";
import AdminEditProduct from "./admin/AdminEditProduct";
import AdminAddBrand from "./admin/AdminAddBrand";
import AdminOrder from "./admin/AdminOrder";
import ProductInfo from "./pages/ProductInfo";
import Preorder from "./pages/Preorder";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import TrackOrder from "./pages/TrackOrder";
import Orders from "./user/Orders";
import Address from "./user/Address";
import Account from "./user/Account";
import ViewOrder from "./user/ViewOrder";
import AdminViewOrder from "./admin/AdminViewOrder";
import AdminAddCoupon from "./admin/AdminAddCoupon";
import PhotoUpload from "./admin/PhotoUpload";
import AdminQueries from "./admin/AdminQueries";
import AdminGallery from "./admin/AdminGallery";
import AdminNotification from "./admin/AdminNotification";
import AdminAddFunds from "./admin/AdminAddFunds";
import Wallet from "./user/Wallet";
import ReferAndEarn from "./user/ReferAndEarn.js";
import Query from "./user/Query";
import { gapi } from "gapi-script";
import { useEffect, useState } from "react";
import Loader from "./pages/Loader";
import axios from "axios";
import Maintenance from "./user/Maintenance.js";
import { message } from "antd";
import AdminBanners from "./admin/AdminBanners.js";
import AdminReferAndEarn from "./admin/AdminReferAndEarn.js";
import APIDocs from "./pages/APIDocs.js";
import ServiceList from "./pages/ServiceList.js";

function App() {
  const clientId =
    "457388804534-e3sou8bmis2fgd9dv08emsc31sn0gn37.apps.googleusercontent.com";

  useEffect(() => {
    function start() {
      gapi.client.init({ clientId: clientId, scope: "" });
    }
    gapi.load("client:auth2", start);
  }, []);

  const [website, setWebsite] = useState(true);
  const [loading, setLoading] = useState(false);

  async function getWebsite() {
    try {
      setLoading(true);
      const res = await axios.get("/api/admin/get-website");
      if (res.data.success) {
        setWebsite(res.data.data.website);
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getWebsite();
  }, []);

  return (
    <BrowserRouter>
      {loading ? (
        <Loader />
      ) : website ? (
        <Routes>
          {/* pages */}
          <Route path="/:token?" element={<Home />} />
          <Route
            path="/register"
            element={
              <PublicRoute>
                <Register />
              </PublicRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route path="/forgot-password" element={<ForgotPass />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/games" element={<PhoneSkins />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/search" element={<Search />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/track-order" element={<TrackOrder />} />
          <Route path="/success" element={<Success />} />
          <Route path="/failure" element={<Failure />} />
          <Route path="/product/:name?" element={<ProductInfo />} />
          <Route path="/preorder/:name?" element={<Preorder />} />
          {/* <Route path="/checking-status" element={<CheckStatus />} /> */}
          {/* <Route path="/faq" element={<FrequentlyAskedQuestions />} /> */}
          <Route
            path="/refer-earn"
            element={
              <ProtectedRoute>
                <ReferAndEarn />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wallet"
            element={
              <ProtectedRoute>
                <Wallet />
              </ProtectedRoute>
            }
          />
          <Route
            path="/query"
            element={
              <ProtectedRoute>
                <Query />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoute>
                <Orders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/address"
            element={
              <ProtectedRoute>
                <Address />
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-account"
            element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            }
          />
          <Route
            path="/view-order/:id?"
            element={
              <ProtectedRoute>
                <ViewOrder />
              </ProtectedRoute>
            }
          />
          {/* <Route path="/service" element={<Service />} /> */}
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          {/* ======================== USER PAGES =============================== */}
          {/* ========== EMAIL VERIFY */}
          <Route
            path="/user-dashboard/:cart?"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/api-docs"
            element={
              // <PublicRoute>
              <APIDocs />
              // </PublicRoute>
            }
          />
          <Route
            path="/service-list"
            element={
              <ServiceList />
            }
          />
          {/* ======================== USER PAGES =============================== */}
          {/* ======================== ADMIN PAGES =============================== */}
          <Route
            path="/admin-login"
            element={
              <PublicRoute>
                <AdminLogin />
              </PublicRoute>
            }
          />
          <Route
            path="/admin-dashboard"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-orders"
            element={
              <ProtectedRoute>
                <AdminOrder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-view-order/:id?"
            element={
              <ProtectedRoute>
                <AdminViewOrder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-products"
            element={
              <ProtectedRoute>
                <AdminProduct />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-add-product"
            element={
              <ProtectedRoute>
                <AdminAddProduct />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-edit-product/:id?"
            element={
              <ProtectedRoute>
                <AdminEditProduct />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-add-brand"
            element={
              <ProtectedRoute>
                <AdminAddBrand />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-test"
            element={
              <ProtectedRoute>
                <PhotoUpload />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-add-coupon"
            element={
              <ProtectedRoute>
                <AdminAddCoupon />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-users"
            element={
              <ProtectedRoute>
                <AdminUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-resellers"
            element={
              <ProtectedRoute>
                <AdminResellers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-edit-user/:id?"
            element={
              <ProtectedRoute>
                <EditUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-payments"
            element={
              <ProtectedRoute>
                <AdminPayments />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-bulk-email"
            element={
              <ProtectedRoute>
                <AdminBulkEmail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-queries"
            element={
              <ProtectedRoute>
                <AdminQueries />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-gallery"
            element={
              <ProtectedRoute>
                <AdminGallery />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-notification"
            element={
              <ProtectedRoute>
                <AdminNotification />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-funds"
            element={
              <ProtectedRoute>
                <AdminAddFunds />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-banners"
            element={
              <ProtectedRoute>
                <AdminBanners />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-refer-earn"
            element={
              <ProtectedRoute>
                <AdminReferAndEarn />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-flash-sale"
            element={
              <ProtectedRoute>
                <AdminFlashSale />
              </ProtectedRoute>
            }
          />
          {/* ======================== ADMIN PAGES =============================== */}
        </Routes>
      ) : (
        <Routes>
          {/* <Route path="/" element={<Maintenance />} /> */}
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/admin-login" element={<Login />} />
          <Route path="*" element={<Maintenance />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
