import CryptoJS from 'crypto-js';

export const encryptData = (data) => {
    const key = 'your-secret-key'; // Use a strong key
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();

    return ciphertext;
};

// // Function to encrypt data
// export function encryptData(plaintext) {
//     const iv = crypto.randomBytes(16); // Generate a new Initialization Vector (IV) for each encryption
//     const cipher = crypto.createCipheriv('aes-256-cbc', encryptionKey, iv);
//     let encrypted = cipher.update(plaintext, 'utf8', 'hex');
//     encrypted += cipher.final('hex');
//     return `${iv.toString('hex')}:${encrypted}`;
// }

// Function to decrypt data


