import React from 'react'
import APIDocsLayout from '../components/Layout/APIDocsLayout'
import APIDocsContentLayout from '../components/Layout/APIDocsContentLayout'
import "./APIDocs.css"

const APIDocs = () => {
    return (
        <APIDocsLayout>
            <APIDocsContentLayout>
                <div className="api-docs">
                    <h1>Documentation</h1>
                    <h3>DerraGames.in integration
                    </h3>
                    <p>
                        DeeeraGames.in API documentation
                        The DeeraGames.in API is a service used to integrate features on DeeraGames.in with partners.
                        <br />

                        DeeraGames.in API's consists of:
                        <ul>
                            <li>
                                <code>
                                    Service

                                </code>
                            </li>
                            <li>
                                <code>
                                    Order


                                </code>
                            </li>
                            <li>
                                <code>
                                    Status


                                </code>
                            </li>
                        </ul>

                    </p>
                    <h5>Production URL: https://deeragames.in/api/
                    </h5>
                </div>

            </APIDocsContentLayout>
        </APIDocsLayout>
    )
}

export default APIDocs