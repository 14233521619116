import { message, Pagination } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout/Layout';
import './ServiceList.css'
import Loader from './Loader';


const ServiceList = () => {
    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [selectedProductDetails, setSelectedProductDetails] = useState(null)
    const [loading, setLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const getAllProducts = async () => {
        try {
            setLoading(true);
            const res = await axios.get("/api/product/get-all-products");
            if (res.data.success) {
                setProducts(res.data.data);
                setSelectedProduct(res.data.data[0].name)

            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    };


    const getProduct = async (name) => {
        try {
            setLoading(true)
            const res = await axios.post("/api/product/get-product-by-name", {
                name
            });
            if (res.data.success) {
                console.log(res.data.data)
                setSelectedProductDetails(res.data.data)

            }
            //  else {
            //     message.error(res.data.message);
            // }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            setPageLoading(false)
        }
    };
    useEffect(() => {
        getAllProducts()
    }, [])
    useEffect(() => {
        getProduct(selectedProduct)
    }, [selectedProduct])


    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
    };




    return (
        <Layout>
            <div className='service-list ' style={{ minHeight: '61vh' }}>
                {pageLoading ? <Loader /> :
                    <div className="container mb-0">
                        <div className="form-fields pt-3">
                            <select
                                className="text-dark"
                                name="month"
                                value={selectedProduct}
                                onChange={(e) => setSelectedProduct(e.target.value)}
                            >
                                <option value="">Select Service</option>
                                {products.map((product, i) => <option key={i} value={product.name}>{product.name}</option>)}


                            </select>
                        </div>
                        {loading ? (
                            <div class="spinner-border text-white" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            <>
                                <table className="table user-table mb-0 mt-3 rounded">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Service</th>
                                            <th>Price</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            selectedProductDetails?.cost?.map((service, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{service?.id}</td>

                                                        <td>
                                                            <small>{service?.amount}</small>
                                                        </td>
                                                        <td>
                                                            <small>{service?.price}</small>
                                                        </td>

                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                                <Pagination
                                    className="mt-3"
                                    current={currentPage}
                                    pageSize={pageSize}
                                    total={selectedProductDetails?.cost?.length}
                                    onChange={handlePageChange}
                                    showSizeChanger
                                    onShowSizeChange={handlePageSizeChange}
                                />
                            </>
                        )}
                    </div>
                }


            </div>

        </Layout>
    )
}

export default ServiceList